.btn-primary {

    display: inline-block;
    outline: 0;
    border: none;
    cursor: pointer;
    line-height: 1.2rem;
    font-weight: 900;
    background: rgb(0, 102, 255);
    padding: 8px 14px 9px;
    font-size: 15px;
    border-radius: 4px;
    color: #fff;
    height: 36px;
    transition: all 75ms ease-in-out;

    :hover {
        box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
    }

}

.btn-secondary {

    display: inline-block;
    outline: 0;
    border: none;
    cursor: pointer;
    line-height: 1.2rem;
    font-weight: 900;
    background: grey;
    padding: 8px 14px 9px;
    font-size: 15px;
    border-radius: 4px;
    color: #fff;
    height: 36px;
    transition: all 75ms ease-in-out;

    :hover {
        box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
    }

}

.btn-danger {

    display: inline-block;
    outline: 0;
    border: none;
    cursor: pointer;
    line-height: 1.2rem;
    font-weight: 900;
    background: rgb(255, 0, 0);
    padding: 8px 14px 9px;
    font-size: 15px;
    border-radius: 4px;
    color: #fff;
    height: 36px;
    transition: all 75ms ease-in-out;

    :hover {
        box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
    }
}