.profile-loader {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-wrapper {
    background-color: #F5F5F5;
    overflow: hidden;
}

// .profile-docent-form {
//     // background-color: rgba(182, 182, 182, 0.315);
//     // padding: 3rem;
// }

.form-background {
    background-color: white !important;
}

.profile-docent-name {
    padding-top: 1em;
}

.profile-docent-form-wrapper>div:not(:last-of-type) {
    background-color: white;
    margin-bottom: 2em;
    border-radius: 10px;
    padding: 1em;
}

.profile-school-form-wrapper>div:not(:last-of-type) {
    background-color: white;
    margin-bottom: 2em;
    border-radius: 10px;
    padding: 1em;
}

.profile-docent-form-contract {
    padding-right: 1em;
    padding-top: 1em;
    width: 40%;
}

.webviewer {
    flex: 1;
    margin: 8px;
    -webkit-box-shadow: 1px 1px 10px #999;
    box-shadow: 1px 1px 10px #999;
}

.h2 {
    padding-top: 1em;
    padding-bottom: 0.5em;
}

.btn-contact {
    width: 15rem !important;
    margin-right:1rem;
}
.btn-blue{
    background-color: #17a2b8;
}