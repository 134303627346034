  .form-group.required .control-label:after {
    content: "*";
    color: red;
  }
  .form-group.required .form-check-label:after {
    content: "*";
    color: red;
  }

.form-control::placeholder {
    opacity: 0.75 !important;
}