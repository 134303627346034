.scroll-top-wrapper {
    display: flex;
    justify-content: flex-end;
}

.scrollTop {
    position: fixed;
    bottom: 50px;
    right: 50px;
    align-items: center;
    height: 80px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    font-size: 80px;
    text-align: right;
    transform: rotate(-90deg);
    
    background-image: linear-gradient(326deg, #7e3549 0%, #d7816a 74%);
    background-size: 100%;
    background-clip: text;
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

}

.scrollTop:hover {
    opacity: 1;
}