@import "../../assets/styles/colors.scss";

/* The side navigation menu */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $bmo-red;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  &--wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 100;
  }
}

.close-btn-burger {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}

.bmo-logo {
  width: 35px;
  height: auto;
  margin: 0rem 1rem;
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: white;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #818181;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.bmo-red {
  .container {
    display: inline-block;
    cursor: pointer;
    margin: 0;
    width: 35px;
    margin-right: 1rem;
  }
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}

/* Rotate first bar */
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 7px);
}

/* Fade out the second bar */
.change .bar2 {
  opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.change {
  cursor: pointer;
  margin-right: 2.5rem;
}

.sign-out {
  position: absolute;
  bottom: 0;
  background-color: #818181;
  cursor: pointer;
  width: 100%;
  padding: 1rem;
  text-align: center;

  p {
    color: white;
  }
}

.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;

  a {
    font-size: 20px;
  }
}

.bmo-red {
  margin-left: 0;
  transition: margin-left 0.5s;
}

.nav-margin {
  margin-left: 250px;
}

.nav-width {
  width: 250px;
}

.close-btn-cross {
  position: absolute;
  top: 0;
  margin: 1rem;
  margin-left: 0;
  width: 35px;
}

.bar-empty {
  display: none;
}
.sidenav--wrapper{
  z-index:1301
}