.edit-btn {
    padding: 0.25rem;
    position: absolute;
    line-height: 2.5rem;
    :hover {
        box-shadow: none;
    }

    &--wrapper {
        display: flex;
        flex-direction: row-reverse;
        position: relative;
    }

    span {
        transform: rotate(120deg);
        writing-mode: tb-rl;
        font-size: 1.5rem;
        width: 36px;
    }
}