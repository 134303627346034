@import "../../assets/styles/colors.scss";

.profile-bar-text{
    color:white;
}
.dropdown-container-user{
    position: relative;
    background-color: $bmo-red;
    z-index: 1;
}
.dropdown-container-user{
    position: absolute;
}