@import "../../assets/styles/colors.scss";

thead {
    background-color: grey;

    span {
        color: white;
    }

    button {
        color: white !important;
    }
}

.dx-g-bs4-table {
    thead {
        th {
            background-color: grey;
            border-bottom: none !important;
            border-top: none !important;
        }
    }
}

.page-item.active .page-link {
    z-index: 0;
}

.docenten-vog {
    .row {
        max-width: 100%;
        margin-right: 0px;
        margin-left: 0px;
    }

    .tabs-area {
        padding: 0 !important;
        margin: 0;

        .container {
            max-width: 100%;
            padding: 0;
            margin: 0;
        }

        .react-tabs__tab-panel--selected {
            padding: 0;
        }

        .col-lg-12 {
            padding: 0;
        }

        .tab-style--1 {
            justify-content: center;
            background-color: gray;
            color: white;
            max-width: 100%;
            margin: 0;
        }

        ul.tab-style--1 li.react-tabs__tab--selected {
            color: white;
        }
    }
}